import React from "react";
import Nav from "../nav/Nav.jsx";
import "./about.css";
import Hero from "../hero/Hero.jsx";
import abouthero from "../../assets/LecturerImages/AcademicAdvisor.Dr.DavidGathura/lec6.certificate.jpg";
import Footer from "../footer/Footer.jsx";

const About = () => {
  return (
    <>
      <Nav />
      <Hero hero="aboutHero" heroImage={abouthero} heroTitle="ABOUT US" />
      <br />
      <div className="aboutus">
        <div className="aboutus-container">
          <div className="ourhistory">
            <img
              className="aboutus-bg"
              src={require("../../assets/DoveImages/dove10.png")}
              alt=""
            />
            <h1>Our History</h1>
            <p></p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default About;
