import React, { useState } from "react";
import Nav from "../nav/Nav.jsx";
import "./home.css";
import Hero from "../hero/Hero.jsx";
import homeHero from "../../assets/GraduationImages/gradImage7.jpg";
import homeslides from "../../assets/carouselImages.json";
import logo from "../../assets/impartation institute logo.jpg";
import Coursecomponents from "../coursecomponents/Coursecomponents.jsx";
import maptoinstitute from "../../assets/maptoYMCAoffices.png";
import Footer from "../footer/Footer.jsx";
import Verse from "./Verse.jsx";
import Staffcomponents from "../portfolio/staffcomponents/Staffcomponents.jsx";

const Home = () => {
  const [openVerse, setOpenVerse] = useState(false);

  return (
    <>
      <Verse open={openVerse} onclose={() => setOpenVerse(false)} />

      <Nav />
      <div className="hero-container">
        <Hero
          hero="homeHero"
          heroImage={homeHero}
          heroTitle="IMPARTATION LEADERSHIP"
          heroTitle2="BIBLE TRAINING INSTITUTE"
          heroText="Christian Ministry Leadership"
          heroLogo={logo}
        />
      </div>
      <br />
      <div className="home-container">
        <div className="home-contents">
          <div className="header-container">
            <p>
              Impartation Leadership Bible Training Institute is a Bible
              centered College teaching and equipping Ministry leaders to do the
              work of the ministry, effectively and in excellence. It has
              powerful lecturers who are born again and well founded in the Word
              and sound doctrine.
            </p>
          </div>
          <button
            onClick={() => setOpenVerse(true)}
            className="institute-bible-motto-verse"
          >
            Ephesians 4:11-12
          </button>

          <br />

          <Coursecomponents />
          {/* <Staffcomponents /> */}
          <div className="footer-container">
            <div className="maptoinstitute">
              <h3>Come Visit Us</h3>
              <a
                className="onlineinstitutelocation"
                href="https://www.google.com/maps/place/Ymca+-+Nyeri/@-0.4297152,36.9545562,17z/data=!4m6!3m5!1s0x182860a6f92b1e73:0x115d18d239d89edf!8m2!3d-0.4297206!4d36.9571311!16s%2Fg%2F1tfhwnpq?entry=ttu"
              >
                <img src={maptoinstitute} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default Home;
