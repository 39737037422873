import React, { useState } from "react";
import "./graduation.css";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
/* import GradImages from "../../../assets/carouselGraduationImages.json"; */

const Graduation = ({ data }) => {
  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };
  const prevSlide = () => {
    setSlide(slide === data.length + 1 ? 0 : slide - 1);
  };

  return (
    <>
      <div className="graduation">
        <h2>Our Latest Graduation Ceremony</h2>
        <p>
          Experience our latest graduation ceremony held on ------ through the
          images and video in our gallery below
        </p>
        <br />
        <div className="graduation-image">
          <BsChevronCompactLeft
            className="arrow arrow-left"
            onClick={prevSlide}
          />

          {data.map((item, idx) => {
            return (
              <img
                src={item.src}
                alt={item.alt}
                key={idx}
                className={slide === idx ? "slide" : "slide slide-hidden"}
              />
            );
          })}

          <BsChevronCompactRight
            className="arrow arrow-right"
            onClick={nextSlide}
          />
        </div>
      </div>
    </>
  );
};

export default Graduation;
