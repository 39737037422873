export const Courseitems = [
  {
    title1: "MORE on our Certificate Course",
    url1: "/courses",
    cName1: "course-links",
  },
  {
    title2: "MORE on our Diploma Course",
    url2: "/courses",
    cName2: "course-links",
  },
  {
    title3: "MORE on our Degree Course",
    url3: "/courses",
    cName3: "course-links",
  },
  {
    title4: "MORE on our Masters Course",
    url4: "/courses",
    cName4: "course-links",
  },
];
