import React, { useState } from "react";
import "./degree.css";
import degGraduates from "../../../assets/GraduationImages/graduates/degree-graduates2(rb).png";
import degGraduates2 from "../../../assets/GraduationImages/graduates/degree-graduates3.png";
import Register from "../../register/Register.jsx";

const Degree = () => {
  const [openRegister, setOpenRegister] = useState(false);

  return (
    <>
      <div className="course-content">
        <Register
          open={openRegister}
          onclose={() => setOpenRegister(false)}
          feetext={"Degee Course Fee"}
          totalfee={"Ksh 42,000"}
        />

        <div className="degree-course">
          {/* <img
            className="degree-bg"
            src={require("../../../assets/DoveImages/dove3.png")}
            alt=""
          /> */}
          <div className="degImage">
            <img alt="degree graduates" src={degGraduates} />
          </div>
          <div className="deg-detail-text">
            <h2>DEGREE </h2>
            <h3>course detailing</h3>
            <br />
            <p>
              The immediate upgrade over the diploma class which is a THREE Year
              course of study with a minimum number of THIRTY SIX units to be
              covered.
            </p>
            <br />
            <div class="courseinfo">
              <div class="courseunits-text">Course Units</div>
              <div class="unit">1. The Five Fold Ministry</div>
              <div class="unit">2. Call to Ministry</div>
              <div class="unit">3. The Will of God in Ministry</div>
              <div class="unit">
                4. Spiritual Warfare <text class="unitpart">Part 1-7</text>
              </div>
              <div class="unit">
                5. Pastoral Ministry <text class="unitpart">Part 1-6</text>
              </div>
              <div class="unit">
                6. Intercessory <text class="unitpart">Part 1-5</text>
              </div>
              <div class="unit">7. Leadership Vision for the City</div>
              <div class="unit">
                8. Chaplaincy <text class="unitpart">Part 1</text>
              </div>
              <div class="unit">9. Excellency in Ministry</div>
              <div class="unit">10. Christian Counselling</div>
              <div class="unit">11. Character Development</div>
              <div class="unit">12. Community Development and Humanitarian</div>
              <div class="unit">13. Blood Covenants</div>
              <div class="unit">
                14. Church Government <text class="unitpart">Part 2</text>
              </div>
              <br />
              <div class="fees-structure-text">Fees Structure</div>
              <div class="fee">
                REGISTRATION = <text class="feeamount">1000/=</text>
              </div>
              <div class="fee">
                UNITS (36 units) = <text class="feeamount">1000/=</text>
              </div>
              <div class="fee">
                GRADUATION = <text class="feeamount">5000/=</text>
              </div>
              <br />
              <div class="total-fee">
                TOTAL AMOUNT = <text class="feeamount">42,000/=</text>
              </div>
              <br />
              <button
                onClick={() => setOpenRegister(true)}
                className="degRegisterBtn"
              >
                Register
              </button>
            </div>
          </div>
          <div>
            <div className="degImage">
              <img alt="degree graduates" src={degGraduates2} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Degree;
