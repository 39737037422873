import React from "react";
import "./socialmedia.css";
import Content from "./Content";

const contents = [
  {
    className: "fa-solid fa-phone",
    media: "phone",
    text: "+254783084097, +254722305131, +254795216423",
    id: 1,
  },
  {
    className: "fa-brands fa-whatsapp",
    media: "whatsapp",
    text: " +254722305131 or +254783084097",
    id: 2,
  },
  {
    className: "fa-solid fa-envelope",
    media: "Email",
    text: "impartationleadership22@gmail.com",
    id: 3,
  },
  {
    className: "fa-brands fa-facebook",
    media: "facebook",
    text: "Impartation Leadership Bible Training Institute",
    id: 4,
  },
  {
    className: "fa-brands fa-twitter",
    media: "twitter",
    text: "Impartation Leadership Bible Training Institute",
    id: 5,
  },
];

const contentsComponent = contents.map((content) => (
  <Content key={content.id} media={content.media} text={content.text} />
));

const Socialmedia = ({ open, onclose }) => {
  if (!open) return null;

  return (
    <div className="contactsn socialmedia-background">
      <div className="contactsnsocialmedia">
        <p onClick={onclose} className="closesocialmedia">
          x
        </p>
        <div className="socialmedia-content">
          <h2>{contentsComponent}</h2>
        </div>
      </div>
    </div>
  );
};

export default Socialmedia;
