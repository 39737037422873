import React from "react";
import "./admissions.css";

const Admissions = () => {
  return (
    <>
      <div className="admissions-content">
        <img
          className="admissions-bg"
          src={require("../../../assets/DoveImages/dove12.png")}
          alt=""
        />
        <div className="admissions">
          <h1>ADMISSIONS</h1>
        </div>
      </div>
    </>
  );
};

export default Admissions;
