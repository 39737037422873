import React from "react";
import safaricomLogo from "../../assets/safaricomlogo.png";
import "./register.css";

const Register = ({ open, onclose, feetext, totalfee }) => {
  if (!open) return null;

  return (
    <div className="registration-background" onClick={onclose}>
      <div className="registration">
        <p onClick={onclose} className="closeregister">
          x
        </p>
        <div className="register">
          <img
            className="safaricomlogo"
            src={safaricomLogo}
            alt="safaricom logo"
          />

          <br />
          <div className="registertext">Lipa na Mpesa</div>
          <div className="registertext">Till No</div>
          <div className="till-no">8772260</div>
          <div className="registertext">{feetext}</div>
          <div className="totalfeeamount">{totalfee}</div>

          <br />
        </div>
      </div>
    </div>
  );
};

export default Register;
