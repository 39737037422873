import React from "react";
import certGraduates from "../../assets/GraduationImages/graduates/certificate-graduates2(rb).png";
import dipGraduates from "../../assets/GraduationImages/graduates/diploma-graduates1(rb).png";
import degGraduates from "../../assets/GraduationImages/graduates/degree-graduates2(rb).png";
import mastersGraduates from "../../assets/LecturerImages/lecturers1(rb).png";
import "./coursecomponents.css";
import { Link, useNavigate } from "react-router-dom";
import { Courseitems } from "./Courseitems";

const Coursecomponents = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/courses");
  };
  return (
    <div className="coursecomponents">
      <h1>Our Courses</h1>
      <p>
        Before you get to choose where you would like to start, we would like
        you to know that we offer our bible training education from the basic
        scratch all the way up to the masters course. Brief detailing of the
        various courses is provided below with much more of the course details
        provided later.
      </p>
      <br />

      <div className="thefourcourses">
        <div className="certificate-course">
          <div className="cert-text">
            <h2>CERTIFICATE</h2>
            <h3>course</h3>
            <p>
              This is a ONE Year course of study. The minimum number of units to
              be covered in this course is FOUR Units.
            </p>
            {Courseitems.map((item, index) => {
              return (
                <li key={index}>
                  <Link
                    className={item.cName1}
                    onClick={handleClick}
                    to={item.url1}
                  >
                    {item.title1}
                  </Link>
                </li>
              );
            })}
          </div>
          <div className="certImage">
            <img alt="certificate graduates" src={certGraduates} />
          </div>
        </div>
        <div className="diploma-course">
          <div className="dipImage">
            <img alt="diploma graduates" src={dipGraduates} />
          </div>
          <div className="dip-text">
            <h2>DIPLOMA </h2>
            <h3>course</h3>
            <p>
              This is a TWO Year course of study. The minimum number of units to
              be covered in this course is TEN Units.
            </p>
            {Courseitems.map((item, index) => {
              return (
                <li key={index}>
                  <Link
                    className={item.cName2}
                    onClick={handleClick}
                    to={item.url2}
                  >
                    {item.title2}
                  </Link>
                </li>
              );
            })}
          </div>
        </div>
        <div className="degree-course">
          <div className="deg-text">
            <h2>DEGREE </h2>
            <h3>course</h3>
            <p>
              This is a THREE Year course of study. The minimum number of units
              to be covered in this course is THIRTY SIX Units.
            </p>
            {Courseitems.map((item, index) => {
              return (
                <li key={index}>
                  <Link
                    className={item.cName3}
                    onClick={handleClick}
                    to={item.url3}
                  >
                    {item.title3}
                  </Link>
                </li>
              );
            })}
          </div>
          <div className="degImage">
            <img alt="degree graduates" src={degGraduates} />
          </div>
        </div>
        <div className="masters-course">
          <div className="mastersImage">
            <img alt="masters graduates" src={mastersGraduates} />
          </div>
          <div className="masters-text">
            <h2>MASTERS </h2>
            <h3>course</h3>
            <p>This is a THREE Year course of study.</p>
            <p>
              The minimum number of units to be covered in this course is TWELVE
              Units.
            </p>
            <p>
              The maximum number of units to be covered in this course is
              SIXTEEN Units.
            </p>
            {Courseitems.map((item, index) => {
              return (
                <li key={index}>
                  <Link
                    className={item.cName4}
                    onClick={handleClick}
                    to={item.url4}
                  >
                    {item.title4}
                  </Link>
                </li>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coursecomponents;
