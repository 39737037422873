import React from "react";
import "./staff.css";

const Staffimages = ({ image1, image2, image3 }) => {
  return (
    <div>
      <div className="lecturerimages">
        <img className="image1" src={image1} alt="" />
        <img className="image2" src={image2} alt="" />
        <img className="image3" src={image3} alt="" />
      </div>
    </div>
  );
};

export default Staffimages;
