export const Navitems = [
  {
    title: "Home",
    url: "/",
    cName: "nav-links",
    hiddencName: "nav-links-hidden",
    icon: "fa-solid fa-house",
  },
  {
    title: "Courses",
    url: "/courses",
    cName: "nav-links",
    hiddencName: "nav-links-hidden",
    icon: "fa-solid fa-graduation-cap",
  },
  {
    title: "Portfolio",
    url: "/portfolio",
    cName: "nav-links",
    hiddencName: "nav-links-hidden",
    icon: "fa-solid fa-clipboard-user",
  },
  {
    title: "AboutUs",
    url: "/about",
    cName: "nav-links",
    hiddencName: "nav-links-hidden",
    icon: "fa-solid fa-circle-info",
  },
  {
    title: "Contact",
    url: "/contact",
    cName: "nav-links",
    hiddencName: "nav-links-hidden",
    icon: "fa-solid fa-address-book",
  },
  /* {
    title: "",
    url: "/user",
    cName: "nav-links",
    hiddencName: "nav-links-hidden",
    icon: "fa-regular fa-user",
  }, */
];
