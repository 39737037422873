import React, { useState } from "react";
import "./register.css";

const Joininstitute = ({ open, onclose }) => {
  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const client = { fullname, username, phonenumber, email };

    const response = await fetch("/api/clientelle", {
      method: "POST",
      body: JSON.stringify(client),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();

    if (!response.ok) {
      setError(json.error);
    }
    if (response.ok) {
      setFullname("");
      setUsername("");
      setPhonenumber("");
      setEmail("");
      setError(null);
      console.log("A new client has joined. WELCOME!", json);
    }
  };

  if (!open) return null;

  return (
    <div className="join-institute">
      <div className="join">
        <div className="joincontent">
          <p onClick={onclose} className="closejoin">
            x
          </p>
          <h2>Welcome to our Institute</h2>
          <form className="joinus-form" onSubmit={handleSubmit}>
            <p>Fullname</p>
            <input
              type="text"
              onChange={(e) => setFullname(e.target.value)}
              value={fullname}
              className="join-input"
            />
            <p>Username </p>
            <input
              type="text"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              className="join-input"
            />
            <p>Phone Number</p>
            <input
              type="phone"
              onChange={(e) => setPhonenumber(e.target.value)}
              value={phonenumber}
              className="join-input"
            />
            <p>Email</p>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="join-input"
            />
          </form>

          <button type="submit" className="submit">
            Join
          </button>
          {error && <div className="error">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default Joininstitute;
