import React from "react";
import Nav from "../nav/Nav.jsx";
import "./courses.css";
import Hero from "../hero/Hero.jsx";
import coursehero from "../../assets/GraduationImages/gradImage1.jpg";
import Certificate from "./certificate/Certificate.jsx";
import Diploma from "./diploma/Diploma.jsx";
import Degree from "./degree/Degree.jsx";
import Masters from "./masters/Masters.jsx";
import Footer from "../footer/Footer.jsx";

const Courses = () => {
  return (
    <>
      <Nav />
      <Hero
        hero="courseHero"
        heroImage={coursehero}
        heroTitle="Come Study with US"
        heroText="Learn more as you Start from the Beginning"
        heroButtontext="Register"
        heroButtonclass="courseRegisterBtn"
      />
      <br />
      <div className="courses-container">
        <div className="courses-header-container">
          <h1>Our COURSES</h1>
          <p>
            Impartation Leadership Bible Training Institute is a Bible centered
            College teaching and equipping Ministry leaders to do the work of
            the ministry, effectively and in excellence. It has powerful
            lecturers who are born again and well founded in the Word and sound
            doctrine.
          </p>
        </div>
        <Certificate />
        <Diploma />
        <Degree />
        <Masters />
        <br />
        <br />
      </div>
      <Footer />
    </>
  );
};

export default Courses;
