import React, { useState } from "react";
import "./hero.css";
import Register from "../register/Register";

const Hero = (props) => {
  const [openRegister, setOpenRegister] = useState(false);

  const heroImage1 = "../../assets/GraduationImages/gradimage7.jpg";
  const heroImage2 = "../../assets/GraduationImages/gradimage4.jpg";
  const heroImage3 = "../../assets/GraduationImages/gradimage3.jpg";
  const heroImage4 = "../../assets/GraduationImages/gradimage1.jpg";

  return (
    <>
      <div className="instituteHero">
        <div className={props.hero}>
          <div className="heroimg">
            <img
              className="heroImage"
              src={props.heroImage}
              alt="header hero image"
            />
          </div>

          <div id="hero-text">
            <h1>{props.heroTitle}</h1>
            <h2>{props.heroTitle2}</h2>
            <h3>{props.heroTitle3}</h3>
            <p>{props.heroText}</p>
            <br />
            <a
              href={props.heroUrl}
              className={props.heroButtonclass}
              onClick={() => setOpenRegister(true)}
            >
              {props.heroButtontext}
            </a>

            <img id="herologo" src={props.heroLogo} alt="" />
          </div>
        </div>
        <Register open={openRegister} onclose={() => setOpenRegister(false)} />
      </div>
    </>
  );
};

export default Hero;
