import React from "react";
import "./campuses.css";

const Campuses = () => {
  return (
    <>
      <div className="campus-content">
        <img
          className="campus-bg"
          src={require("../../../assets/DoveImages/dove9.png")}
          alt=""
        />
        <img
          className="campus-bg2"
          src={require("../../../assets/DoveImages/dove2.png")}
          alt=""
        />
        <div className="campuses">
          <h1>CAMPUSES</h1>
          <h2>Kerugoya</h2>
          <h2>Embakasi, Nairobi</h2>
          <h2>Karatina</h2>
          <h2>
            <strong>Nyeri</strong>, our Headquaters{" "}
          </h2>
          <h2>Nkubu, Meru</h2>
        </div>
      </div>
    </>
  );
};

export default Campuses;
