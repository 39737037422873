export const Footeritems = [
  {
    title1: "Home",
    url1: "/",
    cName1: "nav-links",
    icon1: "fa-solid fa-house",
  },
  {
    title2: "Courses",
    url2: "/courses",
    cName2: "nav-links",
    icon2: "fa-solid fa-graduation-cap",
  },
  {
    title3: "Portfolio",
    url3: "/portfolio",
    cName3: "nav-links",
    icon3: "fa-solid fa-clipboard-user",
  },
  {
    title4: "AboutUs",
    url4: "/about",
    cName4: "nav-links",
    icon4: "fa-solid fa-circle-info",
  },
  {
    title5: "Contact",
    url5: "/contact",
    cName5: "nav-links",
    icon5: "fa-solid fa-address-book",
  },
  {
    url6: "/user",
    cName6: "nav-links-mobile",
    icon6: "fa-regular fa-user",
  },
];
