import React, { useState } from "react";
import "./diploma.css";
import dipGraduates from "../../../assets/GraduationImages/graduates/diploma-graduates1(rb).png";
import dipGraduates2 from "../../../assets/GraduationImages/graduates/diploma-pic1.png";
import Register from "../../register/Register.jsx";

const Diploma = () => {
  const [openRegister, setOpenRegister] = useState(false);

  return (
    <>
      <div className="course-content">
        <Register
          open={openRegister}
          onclose={() => setOpenRegister(false)}
          feetext={"Diploma Course Fee"}
          totalfee={"Ksh 20,000"}
        />

        <div className="diploma-course">
          {/*  <img
            className="diploma-bg"
            src={require("../../../assets/DoveImages/dove9.png")}
            alt=""
          /> */}
          <div>
            <div className="dipImage">
              <img alt="diploma graduates" src={dipGraduates} />
            </div>
          </div>
          <div className="dip-detail-text">
            <h2>DIPLOMA </h2>
            <h3>course detailing</h3>
            <br />
            <p>
              This is the immediate level upgrade for the certificate class
              which is a TWO Year course of study. The Units covered are
              indicated below with a less popup detailing for each.
            </p>
            <br />
            <div class="courseinfo">
              <div class="courseunits-text">Course Units</div>
              <div class="unit">
                1. The Church <text class="unitpart">Part 2</text>
              </div>
              <div class="unit">
                2. Character Development <text class="unitpart">Part 2</text>
              </div>
              <div class="unit">
                3. Pastorial Ministry Leadership{" "}
                <text class="unitpart">Part 1</text>
              </div>
              <div class="unit">4. Faith Dynamics</div>
              <div class="unit">5. Authority of a Believer</div>
              <div class="unit">6. Church Leadership</div>
              <div class="unit">
                7. Church Government <text class="unitpart">Part 1</text>
              </div>
              <div class="unit">8. Obedience</div>
              <div class="unit">9. Community Development</div>
              <div class="unit">10. Excellency in Ministry</div>
              <br />
              <div class="fees-structure-text">Fees Structure</div>
              <div class="fee">
                REGISTRATION = <text class="feeamount">1000/=</text>
              </div>
              <div class="fee">
                UNITS (7 units) = <text class="feeamount">2000/=</text>
              </div>
              <div class="fee">
                GRADUATION = <text class="feeamount">5000/=</text>
              </div>
              <br />
              <div class="total-fee">
                TOTAL AMOUNT = <text class="feeamount">20,000/=</text>
              </div>
              <br />
              <button
                onClick={() => setOpenRegister(true)}
                className="dipRegisterBtn"
              >
                Register
              </button>
            </div>
          </div>
          <div>
            <div className="dipImage">
              <img alt="diploma graduates" src={dipGraduates2} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Diploma;
