import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./staff.css";
import ProfDansonPassport1 from "../../../assets/LecturerImages/Prof.DansonMuthusi-President of teamworkUniversity.Kenya Chapter/professorDanson.pic2.jpg";
import ProfDansonPassport2 from "../../../assets/LecturerImages/Prof.DansonMuthusi-President of teamworkUniversity.Kenya Chapter/professorDanson.pic3.jpg";
import ProfDansonPassport3 from "../../../assets/LecturerImages/Prof.DansonMuthusi-President of teamworkUniversity.Kenya Chapter/professorDanson.pic1.png";
import BishopRichPassport1 from "../../../assets/LecturerImages/SeniorLecturer.Bishop RIch.DirectorILBTI/BishopRich.pic4.jpg";
import BishopRichPassport2 from "../../../assets/LecturerImages/SeniorLecturer.Bishop RIch.DirectorILBTI/BishopRich.pic1.jpg";
import BishopRichPassport3 from "../../../assets/LecturerImages/SeniorLecturer.Bishop RIch.DirectorILBTI/BishopRich.pic3.jpg";
import lecThreePassport from "../../../assets/LecturerImages/AcademicAdvisor.Dr.DavidGathura/Dr.DavidGathura.png";
import lecFourPassport from "../../../assets/LecturerImages/Dr.TeresaMumbi-DOSRich/Dr.Teresa.jpg";
import lecFivePassport from "../../../assets/LecturerImages/Rev.IreneMutahi-Lecturer/Rev.IreneMutahi.jpg";
import lecSixPassport from "../../../assets/LecturerImages/Pst.SarahWaweru/Pst. Sarah Waweru.jpeg";
import Staffimages from "./Staffimages";
import Socialmedia from "../../contact/Socialmedia";

const Staff = (image1) => {
  const [openSocialMedia, setOpenSocialMedia] = useState(false);

  return (
    <>
      <Socialmedia
        open={openSocialMedia}
        onclose={() => setOpenSocialMedia(false)}
      />

      <div className="staffcomponents">
        <div className="staffintrotext">
          <h1>Meet our STAFF</h1>
          <p>
            Hey there? Meet us, the institute's lecturing staff. We are a team
            of highly educated and God fearing individuals. More about us is
            detailed below.
          </p>
        </div>
        <section class="staff_section">
          <div class="staff_container">
            <div class="details_container">
              <h2>Bishop Dr. JM Rich</h2>
              <section id="details">
                <Staffimages
                  image1={BishopRichPassport1}
                  image2={BishopRichPassport2}
                  image3={BishopRichPassport3}
                />
                <h3>
                  DIRECTOR of Impartation Leadership Bible Training Institute
                </h3>

                <p>
                  Bishop Dr. JM Rich is the senior pastor and presiding Bishop
                  of Faith Impartation Ministries Int'l with its headquaters in
                  Nyeri town. He is currently persuing a PHD in Christian
                  Ministry Leadership (CML) from TEAMWORK CHRISTIAN
                  UNIVERSITY-USA. He holds a master degree in Christian Ministry
                  Leadership (CML) from Teamwork Christian University-USA, a
                  Bachelor of Arts degree in Christian Ministry Leadership from
                  Teamwork Christian University-USA, a Doctorate honorary degree
                  from ZOE LIFE Theological University-USA (2019), a Diploma in
                  Theology and Biblical Studies from Breakthrough International
                  Bible University (2018), a Diploma in Faith Dynamics and
                  Biblical Studies from Faith Bible Institute (2014). He is a
                  certified lecturer with Teamwork Christian University-USA.
                </p>
                <p>
                  He is also the founder of FAITH IMPARTATION MINISTRY Int'l
                  having many branches across the Nation of Kenya and regions
                  beyond. Married to Dr. Trizah Mumbi and Blessed with two great
                  sons, one pursuing a degree in mass communication.
                </p>
                <div class="socialmedia-accounts">
                  <i
                    className="fa-solid fa-phone"
                    onClick={() => setOpenSocialMedia(true)}
                  ></i>

                  <a
                    href="https://www.facebook.com/apstjm.rich"
                    target="_blank"
                    rel="noopener"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a>

                  <i
                    className="fa-solid fa-envelope"
                    onClick={() => setOpenSocialMedia(true)}
                  ></i>
                </div>
              </section>
            </div>

            <div class="details_container">
              <h2>Professor Danson Muthusi</h2>
              <section id="details">
                <Staffimages
                  image1={ProfDansonPassport1}
                  image2={ProfDansonPassport3}
                  image3={ProfDansonPassport2}
                />
                <h3>DIRECTOR of Teamwork University</h3>
                <p>
                  Proffesor Danson Muthusi is a senior pastor at Teamwork Bible
                  College. He is also a Bishop He is currently persuing a PHD in
                  Christian Ministry Leadership (CML) from TEAMWORK CHRISTIAN
                  UNIVERSITY-USA. He holds a master degree in Christian Ministry
                  Leadership (CML) from Teamwork Christian University-USA, a
                  Bachelor of Arts degree in Christian Ministry Leadership from
                  Teamwork Christian University-USA, a Doctorate honorary degree
                  from ZOE LIFE Theological University-USA (2019), a Diploma in
                  Theology and Biblical Studies from Breakthrough International
                  Bible University (2018), a Diploma in Faith Dynamics and
                  Biblical Studies from Faith Bible Institute (2014). He is a
                  certified lecturer with Teamwork Christian University-USA.
                </p>
                <div class="socialmedia-accounts">
                  <i
                    className="fa-solid fa-phone"
                    onClick={() => setOpenSocialMedia(true)}
                  ></i>

                  <i
                    className="fa-solid fa-envelope"
                    onClick={() => setOpenSocialMedia(true)}
                  ></i>
                </div>
              </section>
            </div>

            <div class="details_container">
              <h2>Dr. Trizah Mumbi</h2>
              <section id="details">
                <Staffimages
                  image1={lecFourPassport}
                  image2={lecFourPassport}
                  image3={lecFourPassport}
                />

                <h3>DEAN of Studies</h3>
                <span></span>

                <div class="lecturerdetails">
                  <p>
                    Currently persuing masters in Christian Ministry Leadership
                    from Teamwork University.
                  </p>
                  <p>
                    I hold a degree in Christian Ministry Leadership from
                    Teawork University, 2022, and Diploma in Bibilical Studies
                    from East Africa Voice of Prophesy College in 2005.
                  </p>
                </div>

                <div class="socialmedia-accounts">
                  <a href="" target="_blank" rel="noopener">
                    <i className="fa-solid fa-phone"></i>
                  </a>
                  {/* <a
                    href="https://www.facebook.com/apstjm.rich"
                    target="_blank"
                    rel="noopener"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a> */}
                  <a href="" target="_blank" rel="noopener">
                    <i className="fa-solid fa-envelope"></i>
                  </a>
                </div>
              </section>
            </div>

            <div class="details_container">
              <h2>Dr. David Gathura</h2>
              <section id="details">
                <Staffimages
                  image1={lecThreePassport}
                  image2={lecThreePassport}
                  image3={lecThreePassport}
                />
                <br />
                <span></span>

                <div class="lecturerdetails">
                  <p>
                    Currently persuing masters in Christian Ministry Leadership
                    from Teamwork University.
                  </p>
                  <p>
                    I hold a degree in Christian Ministry Leadership from
                    Teawork University, 2022, and Diploma in Bibilical Studies
                    from East Africa Voice of Prophesy College in 2005.
                  </p>
                </div>
                <div class="socialmedia-accounts">
                  <i
                    className="fa-solid fa-phone"
                    onClick={() => setOpenSocialMedia(true)}
                  ></i>

                  {/* <a
                    href="https://www.facebook.com/apstjm.rich"
                    target="_blank"
                    rel="noopener"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a> */}

                  <i
                    className="fa-solid fa-envelope"
                    onClick={() => setOpenSocialMedia(true)}
                  ></i>
                </div>
              </section>
            </div>

            <div class="details_container">
              <h2>Rev. Irene Mutahi</h2>
              <section id="details">
                <Staffimages
                  image1={lecFivePassport}
                  image2={lecFivePassport}
                  image3={lecFivePassport}
                />
                <br />
                <div class="lecturerdetails">
                  <p>
                    Currently persuing masters in Christian Ministry Leadership
                    from Teamwork University.
                  </p>
                  <p>
                    I hold a degree in Christian Ministry Leadership from
                    Teawork University, 2022, and Diploma in Bibilical Studies
                    from East Africa Voice of Prophesy College in 2005.
                  </p>
                </div>

                <div class="socialmedia-accounts">
                  <i
                    className="fa-solid fa-phone"
                    onClick={() => setOpenSocialMedia(true)}
                  ></i>

                  {/* <a
                    href="https://www.facebook.com/apstjm.rich"
                    target="_blank"
                    rel="noopener"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a> */}

                  <i
                    className="fa-solid fa-envelope"
                    onClick={() => setOpenSocialMedia(true)}
                  ></i>
                </div>
              </section>
            </div>

            <div class="details_container">
              <h2>Pastor Sarah Waweru</h2>
              <section id="details">
                <Staffimages
                  image1={lecSixPassport}
                  image2={lecSixPassport}
                  image3={lecSixPassport}
                />
                <br />
                <p>
                  Currently persuing masters in Christian Ministry Leadership
                  from Teamwork University.
                </p>
                <p>
                  I hold a degree in Christian Ministry Leadership from Teawork
                  University, 2022, and Diploma in Bibilical Studies from East
                  Africa Voice of Prophesy College in 2005.
                </p>

                <div class="socialmedia-accounts">
                  <i
                    className="fa-solid fa-phone"
                    onClick={() => setOpenSocialMedia(true)}
                  ></i>

                  {/*  <a
                    href="https://www.facebook.com/apstjm.rich"
                    target="_blank"
                    rel="noopener"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a> */}

                  <i
                    className="fa-solid fa-envelope"
                    onClick={() => setOpenSocialMedia(true)}
                  ></i>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Staff;
