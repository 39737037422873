import React from "react";
import Home from "./components/home/Home";
import Courses from "./components/courses/Courses";
import Contact from "./components/contact/Contact";
import About from "./components/about/About";
import Portfolio from "./components/portfolio/Portfolio";
import { Route, Routes } from "react-router-dom";
import Administrator from "./components/administrator/Administrator";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/administrator" element={<Administrator />} />
      </Routes>
    </>
  );
};

export default App;
