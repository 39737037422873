import React, { useState } from "react";
import "./footer.css";
import Register from "../register/Register";
import Joininstitute from "../register/Joininstitute";
import Socialmedia from "../contact/Socialmedia";
import { Link } from "react-router-dom";
import { Footeritems } from "./Footeritems";

const Footer = () => {
  const [openRegister, setOpenRegister] = useState(false);
  const [openJoin, setOpenJoin] = useState(false);
  const [openSocialMedia, setOpenSocialMedia] = useState(false);

  return (
    <>
      <div className="footer">
        <img
          className="footer-bg"
          src={require("../../assets/DoveImages/dove7.png")}
          alt=""
        />
        <div className="footer-content">
          <div className="top-footer-row">
            <div className="footer-heading">
              <h2>Impartation Leadership</h2>
              <p>Christian Ministry Leadership</p>
            </div>
            <div className="socialmedia">
              <i
                id={1}
                onClick={() => setOpenSocialMedia(true)}
                className="fa-solid fa-phone"
              ></i>

              <i
                id={2}
                onClick={() => setOpenSocialMedia(true)}
                className="fa-brands fa-whatsapp"
              ></i>

              <i
                id={3}
                onClick={() => setOpenSocialMedia(true)}
                className="fa-solid fa-envelope"
              ></i>

              <i
                id={4}
                onClick={() => setOpenSocialMedia(true)}
                className="fa-brands fa-facebook"
              ></i>

              {/* <i
              onClick={() => setOpenSocialMedia(true)}
              className="fa-brands fa-twitter"
            ></i> */}
            </div>
          </div>
          <div className="bottom-footer-row">
            <div className="footer-columncontent">
              <h3>Contact US</h3>
              <div className="info_contact">
                <div className="contact-box">
                  <i className="fa-solid fa-phone" alt="" />
                  <div className="phonenumbers">
                    <p>+254783084097 </p>
                    <p>+254722305131</p>
                    <p>+254795216423</p>
                  </div>
                </div>
                <div className="contact-box">
                  <div className="mail">
                    <i className="fa-solid fa-envelope" alt="" />
                    <p>Impartationleadership22@gmail.com</p>
                  </div>
                </div>

                <div className="contact-box">
                  <div className="post-office">
                    <i className="fa-solid fa-location-dot" alt="" />
                    <p>P.O. BOX 526-10100</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-columncontent">
              <h3>Our Institute</h3>

              {Footeritems.map((item, index) => {
                return (
                  <li key={index}>
                    <Link className={item.cName1} to={item.url1}>
                      <i className={item.icon1}></i>
                      {item.title1}
                    </Link>
                  </li>
                );
              })}
              {Footeritems.map((item, index) => {
                return (
                  <li key={index}>
                    <Link className={item.cName2} to={item.url2}>
                      <i className={item.icon2}></i>
                      {item.title2}
                    </Link>
                  </li>
                );
              })}
              {Footeritems.map((item, index) => {
                return (
                  <li key={index}>
                    <Link className={item.cName3} to={item.url3}>
                      <i className={item.icon3}></i>
                      {item.title3}
                    </Link>
                  </li>
                );
              })}
              {Footeritems.map((item, index) => {
                return (
                  <li key={index}>
                    <Link className={item.cName4} to={item.url4}>
                      <i className={item.icon4}></i>
                      {item.title4}
                    </Link>
                  </li>
                );
              })}
              {Footeritems.map((item, index) => {
                return (
                  <li key={index}>
                    <Link className={item.cName5} to={item.url5}>
                      <i className={item.icon5}></i>
                      {item.title5}
                    </Link>
                  </li>
                );
              })}
              {/* {Footeritems.map((item, index) => {
                return (
                  <li key={index}>
                    <Link className={item.cName6} to={item.url6}>
                      <i className={item.icon6}></i>
                      {item.title6}
                    </Link>
                  </li>
                );
              })} */}

              <a onClick={() => setOpenRegister(true)}>Register for Course</a>
            </div>
            <div className="footer-columncontent">
              <h3>Grab our Newsletter</h3>
              <input
                className="inputemail"
                type="text/email"
                placeholder="your Email"
              />
              <br />
              <button className="getnewsletter" type="submit">
                Get Newsletter
              </button>
            </div>
          </div>
        </div>
        <Register open={openRegister} onclose={() => setOpenRegister(false)} />
        <Joininstitute open={openJoin} onclose={() => setOpenJoin(false)} />
        <Socialmedia
          open={openSocialMedia}
          onclose={() => setOpenSocialMedia(false)}
        />
      </div>
    </>
  );
};

export default Footer;
