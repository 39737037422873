import React from "react";
import "./home.css";

const Verse = ({ open, onclose }) => {
  if (!open) return null;

  return (
    <div onClick={onclose} className="verse-background">
      <div class="verse">
        <img
          className="verse-bg"
          src={require("../../assets/DoveImages/dove8.png")}
          alt=""
        />
        <p onClick={onclose} className="closeverse">
          x
        </p>
        <br />
        <div class="verse-message">
          <sup>11</sup> So Christ himself gave the apostles, the prophets, the
          evangelists, the pastors and teachers,
          <sup>12</sup> to equip his people for works of service, so that the
          body of Christ may be built up
        </div>
        <br />
      </div>
    </div>
  );
};

export default Verse;
