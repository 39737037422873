import React from "react";
import Nav from "../nav/Nav.jsx";
import "./portfolio.css";
import Hero from "../hero/Hero.jsx";
import portfoliohero from "../../assets/LecturerImages/AcademicAdvisor.Dr.DavidGathura/lec6.certificate.jpg";
import Footer from "../footer/Footer.jsx";
import Admissions from "./admissions/Admissions.jsx";
import Campuses from "./campuses/Campuses.jsx";
import Graduation from "./graduation/Graduation.jsx";
import Staff from "./staff/Staff.jsx";

import GradImages from "../../assets/carouselGraduationImages.json";

const Portfolio = () => {
  return (
    <>
      <Nav />

      <Hero
        hero="aboutHero"
        heroImage={portfoliohero}
        heroTitle="Our PORTFOLIO"
        heroText="and more about us"
      />
      <br />
      <div className="portfolio">
        <Admissions />
        <br />
        <br />
        <Campuses />
        <br />
        <br />
        <Graduation data={GradImages} />
        <br />
        <br />
        <Staff />
        <br />
        <br />
        <Footer />
      </div>
    </>
  );
};

export default Portfolio;
