const ClientelleDetails = ({ client }) => {
  return (
    <>
      <div className="client-details">
        <h3>
          <strong>{client.fullname}</strong>
        </h3>
        <p>
          <strong>Username: </strong>
          {client.username}
        </p>
        <p>
          <strong>Phone Number: </strong>
          {client.phonenumber}
        </p>
        <p>
          <strong>Email: </strong>
          {client.email}
        </p>
        <p>{client.createdAt}</p>
      </div>
    </>
  );
};

export default ClientelleDetails;
