import React, { Component, useState } from "react";
import logo from "../../assets/impartation institute logo.jpg";
import "./nav.css";
import { Link } from "react-router-dom";
import { Navitems } from "./Navitems";
import Joininstitute from "../register/Joininstitute";

class Nav extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };
  openJoininstitute = { clicked: false };
  setOpenJoininstitute = () => {
    this.setOpenJoininstitute({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <>
        <nav>
          <img id="logo" src={logo} alt="our logo" href="/" />
          <div>
            <ul
              id="navbar"
              className={this.state.clicked ? "#navbar active" : "#navbar"}
            >
              {Navitems.map((item, index) => {
                return (
                  <li key={index}>
                    <Link
                      className={item ? "hidden-cName" : "cName"}
                      to={item.url}
                    >
                      <i className={item.icon}></i>
                      {item.title}
                    </Link>
                  </li>
                );
              })}
              {/* <button id="join" onClick={this.setOpenJoininstitute}>
                Join US
              </button> */}
              {/*  <Joininstitute onclose={() => this.setOpenJoininstitute} /> */}
            </ul>
          </div>
          <div id="mobile" onClick={this.handleClick}>
            <i
              id="bar"
              className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
            ></i>
          </div>
        </nav>
      </>
    );
  }
}

export default Nav;
