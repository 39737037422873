import React, { useState } from "react";
import Nav from "../nav/Nav.jsx";
import "./contact.css";
import maptoinstitute from "../../assets/maptoYMCAoffices.png";
import Footer from "../footer/Footer.jsx";
import Joininstitute from "../register/Joininstitute.jsx";

const Contact = () => {
  const [openJoininstitute, setOpenJoininstitute] = useState(false);

  return (
    <>
      <Joininstitute
        open={openJoininstitute}
        onclose={() => setOpenJoininstitute(false)}
      />
      <Nav />
      <div className="togetus">
        <img
          className="contact-bg"
          src={require("../../assets/DoveImages/dove4.png")}
          alt=""
        />
        <div className="contactdetails">
          <div className="ournewsletter">
            <h3>Grab our Newsletter </h3>
            <div className="newsletter">
              <input
                className="inputemail"
                type="text/email"
                placeholder="your Email"
              />
              <br />
              <div>
                <button className="getnewsletter" type="submit">
                  Get Newsletter
                </button>
              </div>
            </div>

            {/* <h3>JOIN US</h3>
            <div className="joining-form">
              <button
                onClick={() => setOpenJoininstitute(true)}
                className="welcometoILBTI"
                type="submit"
              >
                JOIN US
              </button>
            </div> */}
          </div>

          <div className="ourcontacts">
            <h3>Contacts and SocialMedia</h3>
            <div className="contacts">
              <div className="contact">
                <i className="fa-solid fa-phone" />
                <p>+254783084097, +254722305131, +254795216423</p>
              </div>
              <div className="contact">
                <i className="fa-brands fa-whatsapp" />
                <p>+254722305131 or +254783084097</p>
              </div>
              <div className="contact">
                <i className="fa-solid fa-envelope" />
                <p>impartationleadership2022@gmail.com</p>
              </div>
              <div className="contact">
                <i className="fa-solid fa-envelope"></i>
                <p>justusmuthini1969@gmail.com</p>
              </div>

              <div className="contact">
                <i className="fa-brands fa-facebook"></i>
                <p>ImpartationLeadership BibleInstitute</p>
              </div>
              {/*  <div className="contact">
                <i className="fa-brands fa-twitter"></i>
                <p>ImpartationLeadership BibleInstitute</p>
              </div> */}
            </div>
          </div>

          <h3>Come Visit Us</h3>
          <p>
            Physically find us at our headquarter offices: the YMCA offices
            close to the Sikh Temple or the Temple Road Primary School, on your
            entry to Nyeri Town, just Before KRA Nyeri Offices.
          </p>
          <br />
          <a
            className="onlineinstitutelocation"
            href="https://www.google.com/maps/place/Ymca+-+Nyeri/@-0.4297152,36.9545562,17z/data=!4m6!3m5!1s0x182860a6f92b1e73:0x115d18d239d89edf!8m2!3d-0.4297206!4d36.9571311!16s%2Fg%2F1tfhwnpq?entry=ttu"
          >
            <img src={maptoinstitute} alt="" />
          </a>
        </div>
      </div>
      <br />
      <br />

      <Footer />
    </>
  );
};

export default Contact;
