import React, { useState } from "react";
import "./certificate.css";
import certGraduates from "../../../assets/GraduationImages/graduates/certificate-graduates2(rb).png";
import certGraduates2 from "../../../assets/GraduationImages/graduates/certificate-graduates(rb).png";
import Register from "../../register/Register.jsx";

const Certificate = () => {
  const [openRegister, setOpenRegister] = useState(false);

  return (
    <>
      <div className="course-content">
        <Register
          open={openRegister}
          onclose={() => setOpenRegister(false)}
          feetext={"Certificate Course Fee"}
          totalfee={"Ksh 10,000"}
        />

        <div className="certificate-course">
          {/* <img
            className="certificate-bg"
            src={require("../../../assets/DoveImages/dove2.png")}
            alt=""
          /> */}
          <div>
            <div className="certImage">
              <img alt="certificate graduates" src={certGraduates} />
            </div>
          </div>
          <div className="cert-detail-text">
            <h2>CERTIFICATE</h2>
            <h3>course detailing</h3>
            <p>
              This is a ONE Year course of study. The Units covered are
              indicated below with a less popup detailing for each.
            </p>
            <br />
            <div className="courseinfo">
              <div className="courseunits-text">Course Units</div>
              <div className="unit">
                1. The Church <text className="unitpart">Part 1</text>
              </div>
              <div className="unit">2. Vision Management</div>
              <div className="unit">
                3. Character Development <text class="unitpart">Part 1</text>
              </div>
              <div className="unit">
                4. Ministerial Ethics <text class="unitpart">Part 1</text>
              </div>
              <br />
              <div className="fees-structure-text">Fee Structure</div>
              <div className="fee">
                REGISTRATION = <text className="feeamount">1000/=</text>
              </div>
              <div className="fee">
                UNITS (4 units) = <text className="feeamount">1000/=</text>
              </div>
              <div className="fee">
                GRADUATION = <text className="feeamount">5000/=</text>
              </div>
              <br />
              <div className="total-fee">
                TOTAL AMOUNT = <text className="feeamount">10,000/=</text>
              </div>
              <br />
              <button
                onClick={() => setOpenRegister(true)}
                className="certRegisterBtn"
              >
                Register
              </button>
            </div>
          </div>
          <div>
            <div className="certImage">
              <img alt="certificate graduates" src={certGraduates2} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Certificate;
