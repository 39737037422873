import React, { useState } from "react";
import "./masters.css";
import mastersGraduates from "../../../assets/LecturerImages/lecturers1(rb).png";
import mastersGraduates2 from "../../../assets/LecturerImages/AcademicAdvisor.Dr.DavidGathura/lec6.certificate.jpg";
import Register from "../../register/Register.jsx";

const Masters = () => {
  const [openRegister, setOpenRegister] = useState(false);

  return (
    <>
      <div className="course-content">
        <Register
          open={openRegister}
          onclose={() => setOpenRegister(false)}
          feetext={"Masters Course Fee"}
          totalfee={"Ksh 45,000"}
        />
        <div className="masters-course">
          {/*  <img
            className="masters-bg"
            src={require("../../../assets/DoveImages/dove9.png")}
            alt=""
          /> */}
          <div>
            <div className="mastersImage">
              <img alt="masters graduates" src={mastersGraduates} />
            </div>
          </div>
          <div className="masters-detail-text">
            <h2>MASTERS </h2>
            <h3>course detailing</h3>
            <p>
              This is the currently most advanced class in our institute with a
              THREE Year course of study.
            </p>

            <p>
              The minimum number of units to be covered in this course is TWELVE
              Units.
            </p>
            <p>
              The maximum number of units to be covered in this course is
              SIXTEEN Units.
            </p>
            <br />
            <div class="courseinfo">
              <div class="courseunits-text">Course Units</div>
              <div class="unit">1. Apologetics</div>
              <div class="unit">2. World Religion</div>
              <div class="unit">3. Developing Leadership Potential</div>
              <div class="unit">
                4. Chaplaincy <text class="unitpart">Part 2</text>
              </div>
              <div class="unit">
                5. Systematic Theology <text class="unitpart">Part 1-2</text>
              </div>
              <div class="unit">6. Thesis</div>
              <div class="unit">7. T.O.C.B.S Team Bible College Intl</div>
              <br />
              <div class="fees-structure-text">Fees Structure</div>
              <div class="fee">
                REGISTRATION = <text class="feeamount">2000/=</text>
              </div>
              <div class="fee">
                UNITS (12 units) = <text class="feeamount">3000/=</text>
              </div>
              <div class="fee">
                GRADUATION = <text class="feeamount">7000/=</text>
              </div>
              <br />
              <div class="total-fee">
                TOTAL AMOUNT = <text class="feeamount">45,000/=</text>
              </div>
              <br />
              <button
                onClick={() => setOpenRegister(true)}
                className="mastersRegisterBtn"
              >
                Register
              </button>
            </div>
          </div>
          <div>
            <div className="mastersImage">
              <img alt="masters graduates" src={mastersGraduates2} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Masters;
