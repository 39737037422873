import React, { useEffect, useState } from "react";
import "./administrator.css";
import Nav from "../nav/Nav";
import ClientelleDetails from "./ClientelleDetails";
import Footer from "../footer/Footer";
import axios from "axios";

function Administrator() {
  const [clientelle, setClientelle] = useState(null);
  const [file, setFile] = useState("");

  const handleUpload = (e) => {
    const formdata = new FormData();
    formdata.append("file", file);
    axios
      .post("http://localhost:4000/upload", formdata)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const fetchClientelle = async () => {
      const response = await fetch("/api/clientelle");
      const json = await response.json();

      if (response.ok) {
        setClientelle(json);
      }
    };

    fetchClientelle();
  }, []);

  return (
    <>
      <Nav />
      <div className="administrator">
        <div className="clientelle">
          <h2>Our CLIENTELLE</h2>
          {clientelle &&
            clientelle.map((client) => (
              <ClientelleDetails key={client._id} client={client} />
            ))}
        </div>
        <div className="media-gallery">
          <p>Upload an IMAGE:</p>
          <br />
          <input
            className="input-image"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
          <button className="upload-image" onClick={handleUpload}>
            Upload
          </button>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
}

export default Administrator;
