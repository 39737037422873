import React from "react";

const Content = ({ className, media, text }) => {
  return (
    <>
      <p onClick={onclose} className="closejoin">
        x
      </p>
      <div className="content-icon">{className}</div>
      <div className="content-media">{media}</div>
      <div className="content-text">{text}</div>
    </>
  );
};

export default Content;
